import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles/Container'
import { Button } from '../components/styles/Button'
import setupWizard from '../images/undraw_setup_wizard_r6mr.svg'
import authentication from '../images/undraw_authentication_fsn5.svg'
import teamSpirit from '../images/undraw_team_spirit_hrr4.svg'

const SolutionsSection = styled.div`
  display: flex;
  flex-direction: column;

  section {
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.remScale[6]};
    margin-bottom: ${props => props.theme.remScale[7]};
  }

  header {
    text-align: center;
    background-image: none;
  }

  img {
    margin-bottom: ${props => props.theme.remScale[6]};
  }

  h2 {
    margin-top: ${props => props.theme.emScale[1]};
    font-size: ${props => props.theme.remScale[7]};
  }

  h3 {
    font-size: ${props => props.theme.emScale[6]};
    margin-left: -${props => `${props.theme.scale[4] + props.theme.scale[0]}rem`};
    padding-left: ${props => props.theme.remScale[4]};
    border-left: ${props => props.theme.remScale[0]} solid
      ${props => props.theme.primary2};
  }

  .solution-body {
    margin-bottom: ${props => props.theme.remScale[6]};
  }

  ul {
    padding-left: ${props => props.theme.remScale[7]};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    section {
      max-width: 300px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-around;

    section {
      flex-basis: 50%;
      max-width: none;
      padding: 0 ${props => props.theme.remScale[6]};
    }

    img {
      width: auto;
      height: 215px;
    }

    ul {
      font-size: ${props => props.theme.emScale[4]};
    }
  }
`

const Price = styled.div`
  width: ${props => props.theme.remScale[17]};
  margin: auto auto ${props => props.theme.remScale[7]};
  padding: ${({ theme }) =>
    `${theme.remScale[9]} ${theme.remScale[11]} ${theme.remScale[10]}`};
  background-color: ${props => props.theme.neutral1};
  text-align: center;
  color: ${props => props.theme.white};
  box-shadow: 0 25px 50px -12px ${props => props.theme.shadow2};
  border-radius: 5px;

  small {
    display: inline-block;
    margin-bottom: ${props => props.theme.remScale[6]};
    color: ${props => props.theme.neutral5};
  }

  strong {
    display: block;
    font-weight: ${props => props.theme.fontWeightBolder};
    font-size: ${props => props.theme.remScale[10]};
    line-height: ${props => props.theme.lineHeightTight};
  }
`

const Solution = () => (
  <Layout
    backgroundImage={setupWizard}
    backgroundFullPage={false}
    backgroundVerticalPosition="-60%"
    backgroundOpacity={0.7}
  >
    <SEO title="Services and Pricing" description="A comprehensive security strategy covers both your systems and your employees." />
    <Container boxed={true}>
      <h1>Services and Pricing</h1>

      <p className="hero">
        A comprehensive security strategy covers both your systems and your
        employees.
      </p>
      <p>
        The most careful employee is vulnerable when using an insecure system,
        and the most secure system can be exposed to attack accidentally by a
        team member.
      </p>

      <SolutionsSection>
        <section>
          <header>
            <img src={authentication} alt="A login screen" />
            <h2 className="h4">Systems Security</h2>
          </header>

          <div className="solution-body">
            <p>Your devices and network are under constant attack.</p>

            <p>
              We proactively protect you using products usually only available
              to enterprise-level companies or government agencies.
            </p>

            <h3>Advanced Protection: Antivirus Evolved</h3>
            <p>
              Powerful artificial intelligence software powered by Cylance
              identifies and neutralizes malicious files before they run,
              eliminating threats before they cost your business.
            </p>

            <h3>Proactive Vulnerability Detection and Response</h3>
            <p>
              First developed for the Department of Defense, Infocyte
              proactively analyzes your systems, users, applications, and more
              to identify and respond to vulnerabilties and hidden attackers.
            </p>
          </div>

          <Price role="text">
            <small>
              Systems Security <span className="sr-only">Price</span>
            </small>
            <strong>$20</strong> per <em>device</em>
            <br /> per month
          </Price>
        </section>
        <section>
          <header>
            <img src={teamSpirit} alt="A team standing together" />
            <h2 className="h4">Team Security</h2>
          </header>

          <div className="solution-body">
            <p>Humans are the weakest link in any security situation.</p>

            <p>
              Team members cause upwards of 95% of breaches. We help train your
              staff to avoid being easy targets for opportunistic criminals.
            </p>

            <h3>Security Awareness Training</h3>
            <p>
              Protect your business by educating your team and identifying and
              managing vulnerable employees.
            </p>

            <h4 className="h6">Included services:</h4>

            <ul>
              <li>
                Interactive training modules educate staff about threats, and
                weekly micro-trainings reinforce learning.
              </li>
              <li>
                Phishing tests keep your team in a strong security mindset.
              </li>
              <li>
                Managers and employees can track progress in the portal
                dashboard.
              </li>
              <li>
                Employees are scored based on tests and phishing attempts,
                telling you who is a risk to your business and needs additional
                help.
              </li>
              <li>Dark Web monitoring detects stolen employee accounts.</li>
            </ul>
          </div>

          <Price>
            <small>Team Security</small>
            <strong>$20</strong> per <em>employee</em>
            <br /> per month
          </Price>
        </section>
      </SolutionsSection>
      <h2 className="h3">
        Ready to learn how to defend your livelihood against cyber attacks?
      </h2>
      <p>
        Request a free security evaluation, and we'll help assess what you need
        to do to protect yourself against today's threats.
      </p>
      <p>
        <Button as={Link} to="/free-security-evaluation">
          Free Security Evaluation <FontAwesomeIcon icon="angle-double-right" />
        </Button>
      </p>
      <p className="small">
        Not quite ready? No problem! <Link to="/contact-us">Contact us</Link>,
        and we'll be happy to answer any questions you have.
      </p>
    </Container>
  </Layout>
)

export default Solution
